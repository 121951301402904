html,button,input,select,textarea {    color: #222;}
html {   /* font-size: 1em;    line-height: 1.4;*/}
::-moz-selection {    background: #b3d4fc;    text-shadow: none;}
::selection {    background: #b3d4fc;    text-shadow: none;}
hr {    display: block; height: 1px; border: 0;  border-top: 1px solid #ccc; margin: 1em 0;  padding: 0;}
audio, canvas, img, video {    vertical-align: middle;}
fieldset {    border: 0;     margin: 0;     padding: 0; }
textarea {    resize: vertical;}
.browsehappy {    margin: 0.2em 0;    background: #ccc;    color: #000;    padding: 0.2em 0;}
.ir {    background-color: transparent;    border: 0;    overflow: hidden;    /* IE 6/7 fallback */    *text-indent: -9999px;}
.ir:before {    content: "";    display: block;    width: 0;    height: 150%;}
.hidden {    display: none !important;    visibility: hidden;}
.visuallyhidden {    border: 0;    clip: rect(0 0 0 0);    height: 1px;    margin: -1px;    overflow: hidden;    padding: 0;  position: absolute;    width: 1px;}
.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus {    clip: auto;    height: auto;    margin: 0;    overflow: visible;    position: static;    width: auto;}
.invisible {    visibility: hidden;}
.clearfix:before, .clearfix:after {    content: " "; /* 1 */    display: table; /* 2 */}
.clearfix:after {    clear: both;}
.clearfix {    *zoom: 1;}
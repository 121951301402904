.main-wrapper {
    position: relative; @include transition(all .6s ease); left:0;
    &.opened{ left:0; }
        // height: 85px;
        border:none;
        // background-color: $gray;
        background-color: transparent;
        position: relative;
    h3{
        font-size: 16px;
        @extend .light-title;
        color: $brand-text-inverted;
        fontsize(1)
        // @extend .center-top;
        // margin:0 0 0 20px; padding: 0;
        &>i{@include fontsize(1.2); margin-right: 7px}
    }

    h1 {
      font-size: 28px;
      font-weight: 300;
    }

    h2 {
      font-weight: 600;
      font-size: 17px;
    }

    .dyn-divider {
      height: 3px;
    }
}

// .wrapper{ padding: 30px 30px}


@media (max-width: $screen-sm-min) {
    .main-wrapper{
        @include transition(all .6s ease); left:0;
        &.opened{left: 300px;}
    }
}



.nav-tabs{
    border-bottom:none; margin-left:10px;
    & > li {  border:0; }
    & > li > a {
        border-radius:0; color:$gray-light; background-color: lighten($gray, 60%); border:0; margin-right: 0;
    }
    & > li.active > a, & > li.active > a:hover, & > li.active > a:focus {
        color:$white; background-color: lighten($gray, 20%); border:0;
    }
}

.tabs-panel, .panel-light .tabs-panel {
	height:100%; margin-top:-18px ; margin-bottom:-18px ; margin-left: -25px; margin-right:-25px;
	& > li { width:100%;}
	& > li  > a { padding:18px 25px; font-size:20px; text-align: center; }
}

.dark-tabs {
    margin:10px -10px -10px; background-color: lighten($gray, 10%); color:$white; @include clearfix();
    .nav-tabs{
        & > li > a {
            background-color: lighten($gray,20%); color:$white; @include fontsize(1.4); padding:15px;
        }
        & > li.active > a, & > li.active > a:hover, & > li.active > a:focus {
            background-color: lighten($gray, 10%);
        }
    }

    .tab-pane {
        padding:20px;
        .form-control{
            @include fontsize(1.3); margin-bottom: 10px;
        }
        .social-input {
            @include clearfix(); 
            .form-control {
                float: left; width: calc(100% - 50px); 
            }
            .settings {
                width: 40px; margin:0; height:45px;
            }
        }
        .social-input-content {
           @include clearfix(); 
            .form-control {
                float: left; width: calc(100% - 155px); @include fontsize(1.3); margin-right: 15px;
            } 
            .settings {
                width: 40px; margin:0; height:45px; float:left; margin-left:2px;
            }
            .settings:last-child{ margin-left: 15px;}
        }
        .pane-footer{ 
            margin:20px -20px -20px; background-color:$gray-light; @include clearfix();
            .btn-publish { border-radius:0; padding:20px 40px; min-width:50%; @include fontsize(1.8);}
        }
    } 
}


@media (min-width: $screen-sm-min) {
	.tabs-panel, .panel-light .tabs-panel {
		& > li { width:auto;}
		& > li  > a { padding:18px 25px; font-size:20px; min-width: 250px;  } 
	}
}
//==tablas
.table {
  &> thead, > tbody, > tfoot {
    &> tr {
      &> th, > td {       
        border-bottom:none;
        border-top: none;
        vertical-align:middle;
      }
      &.active {
          font-weight:bold;
      }
    }
  }
  
  &.table-highlight{
      box-shadow: 0  0 2px #555; background:$gray-ultralight;
      .fa{position:relative; top: -2px}
      &>thead>tr>th{border:none;}
     & >thead>tr>th+th+th{border-left: 4px $gray-light double;}
      
  }
  
}

.table-hover {
  &> tbody > tr:hover, > tbody > tr.active{
   & > td, > th {
      background-color: $blue-pastel;
    }
  }
}

.table-order {
  thead {
    background-color: lighten($gray,20%); color:$white; font-weight:normal;
    th {border:none !important; font-weight:normal;}
  }
  .drag {text-align: center; font-size: 1.8em; color: $gray-light}
  .form-control {
    text-align: center; @include fontsize(1.3); max-width: 40px; margin: auto;
  }
  td, th{
    text-align: center;
  }

  td:first-child, th:first-child {
    text-align: left;
  }
  
  tfoot {
    background-color: lighten($gray,20%);
    & > tr > td { padding:3px; }
    .form-control { width:auto;}
  }
}

.user-table {
  margin-bottom: 64px;
} 

table.table { 
  .extra-info {
    padding:0px 15px; 
    & > div {
      background-color: lighten($gray,10%);; padding: 30px 0px 0px; box-shadow: inset 0px 1px 5px 1px rgba(0,0,0,0.31);
      .form-control { max-width:none; text-align: left; margin-bottom: 30px;}
      label { color: $white; font-weight: 300; text-align: left;}
      &.highligth-footer{
        background-color:lighten($gray,20%); @include clearfix(); margin-left: -15px; margin-right: -15px; padding:0; position:relative; z-index: 1;
        .btn-publish { border-radius: 0;}
        .cancel-link { color:$white; line-height: 74px; margin-left: 20px; font-size:18px;}
      }
    }
  }  
}


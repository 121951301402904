// $icon-font-path: "bower_components/bootstrap-sass-official/vendor/assets/fonts/bootstrap/";
// $fa-font-path: "bower_components/font-awesome/fonts";
$input-height-base: 45px;

// bower:scss
// @import "bower_components/bootstrap-sass-official/vendor/assets/stylesheets/bootstrap.scss";
// @import "bower_components/font-awesome/scss/font-awesome.scss";
// endbower
@import url('https://fonts.googleapis.com/css?family=Lato');
@import "ember-modal-dialog/ember-modal-structure";
@import "ember-modal-dialog/ember-modal-appearance";

@import "ember-cli-bootstrap-4/bootstrap";

@import "fonts";
@import "variables";
@import "defaults";
@import "mixins";
@import "animations";

@import "helper-classes";
@import "icons";
@import "buttons";
// @import "tooltips";
@import "tables";
@import "forms";
@import "labels";
@import "tags";
@import "pagination";
@import "modal";
@import "nav-tabs";
@import "popover";
// @import "thumbnail";
// @import "switch";
@import "datepicker-bootstrap";

// @import "htmldefaults";
// @import "sidebar";
@import "mainwrapper";
@import "quicktools";
@import "userpanel";
@import "assets";
@import "generic";
// @import "categories";
@import "frmb";
@import "print-styles";

// @import "ember-modal-dialog/ember-modal-structure.css";
// @import "ember-modal-dialog/ember-modal-appearance.css";

.textarea2{
width: calc(100% - 16px);
font-size: 14px;
margin: 0;
padding: 0;
}
.box-simple {
  position:fixed;
  display: block;
  width: 360px;
  padding: 26px;
  margin-top: 0;
  margin-bottom: 0;
  height: 98px;
  font-size: 18px;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
}

.box-simple.inverted {
  background-color: #3c3c3c;
  color: #fff;
  position: relative;
  border-radius: 3px;
  display: block;
  width: 360px;
}

.btn-primary {
  box-shadow: 0 2px 0 0 #1b5eab;
  background-color: #3484de;
}
.btn2 {
  border: none;
  font-size: 18px;
  border-radius: 3px;
  padding: 10px 25px;
  font-weight: 300;
}
.btn-block {
  display: block;
  width: 100%;
}
.btn-primary {
  color: #fff;
  background-color: #4A90E2;
  border-color: #3484de;
}
.open.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
  outline: 0;
}
.dropdown-menu {
  display: block;
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 18px;
  text-align: left;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0,0,0,.175);
  background-clip: padding-box;
}


.preMenu {
  word-break:break-all;
  word-wrap:break-word;
  padding:0;
  margin:0;
  border:0;
  background-color:transparent;
}

.answer-label {
  width: 88%
}

.list-group-item-action:hover {
  cursor: pointer;
}

.ember-modal-overlay {
  background: rgba(0,0,0,0.44);
}


@import "ember-cli-bootstrap-4/bootstrap";

@import "ember-power-select";


/*.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn-custom {
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 4px 14px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
}

.upload-btn-wrapper input[type=file] {
  font-size: 10px;
  position: absolute;
  width: 40px;
  height: 100px;
  left: 0;
  top: 0;
  opacity: 0;
}*/



@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&family=Poppins:wght@300;400;500;700&family=Open+Sans:wght@300&display=swap");

$theme-background: #f0f0f0;
$theme-foreground: #ffffff;

$theme-background-alt: #fbfbfb;

$h1: #1a1a1a;
$h1-alt: #666666;
$h2: #2d2d2d;

$h1-dark: #ffffff;
$h1-alt-dark: #979797;
$h2-dark: #f4f4f4;

$link-normal: #0000ff;
$link-hover: #007db3;
$link-visited: #007db3;

$cdmx-background: #55ba72;
$cdmx-background-light: #e9fff2;

$theme-background-dark: #21263b;
$theme-foreground-dark: #ffffff;

$animation-fast: 300ms;
$animation-mid: 500ms;
$animation-slow: 700ms;
$animation-slower: 800ms;
$animation-long: 1200ms;

$theme-font-family: "Inter", sans-serif !important;

@mixin tx-bgc($speed) {
  -webkit-transition: background-color $speed linear;
  -ms-transition: background-color $speed linear;
  transition: background-color $speed linear;
}

@mixin tx-c($speed) {
  -webkit-transition: color $speed linear;
  -ms-transition: color $speed linear;
  transition: color $speed linear;
}

.ember-modal-dialog {
  background: $theme-background-dark;

  .modal-header, .modal-body {
    background: $theme-background-dark;
  }
}

body {
  font-family: $theme-font-family;
}

h1 {
  font-family: $theme-font-family;
  font-size: 2.8rem !important;
  font-weight: 700 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: -0.5px;

  span {
    font-family: $theme-font-family;
    font-size: 1.35rem !important;
    font-weight: 600 !important;
    letter-spacing: 0.3px;
    margin-bottom: 0.5rem;
    &.green {
      font-family: $theme-font-family;
      font-size: 2.8rem !important;
      color: green;
    }
  }
}

h2 {
  font-family: "Inter", sans-serif !important;
  font-size: 2.125rem !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: -0.1px;
}

h3 {
  font-family: "Inter", sans-serif !important;
  font-size: 1.38rem !important;
  font-weight: lighter !important;
  line-height: 1.4 !important;
  margin-bottom: 0.5rem;
}

p {
  font-family: $theme-font-family;
  font-size: 1.15rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;

  &.list-p {
    font-size: 1rem !important;
  }

  &.large {
    font-family: "Inter", sans-serif !important;
    font-size: 1.38rem !important;
    font-weight: lighter !important;
    line-height: 1.5 !important;
    margin-bottom: 0.5rem;
  }
}

a {
  text-decoration: underline !important;

  &:hover {
    color: $link-hover !important;
    text-decoration: none !important;
  }
}

.bg-cdmx-light {
  background-color: #03a340 !important;
  color: white !important;
}

.bg-cdmx-dark {
  background-color: #2a635c;
}

ul {
  &.list-cdmx {
    list-style: none;
    padding-left: 20px;

    li {
      margin-bottom: 14px;
      font-size: 1.2rem;
      line-height: 1.6;
      font-weight: 400;

      &::before {
        content: "\2022";
        color: green;
        font-weight: bold;
        display: inline-block;
        width: 1.5em;
        margin-left: -1.3em;
      }
    }
  }

  &.list-unstyled li::before { content: ""; }
}

#dropdownMenuButton {
  &::after {
    margin-top: 12px;
    margin-left: 8px;
  }
  &:hover {
    img {
      opacity: 0.8;
    }
  }
}

.inner-header {
  h1 {
    font-size: 3.3rem !important;
    color: $h1 !important;
  }
}

.card {
  .card-header {
    h3 {
      font-size: 2.2rem !important;
      font-weight: 600;
    }
  }
}

.navbar .navbar-nav {
  width: 100%;
  text-align: center;

  li {
    vertical-align: middle !important;
    width: 154px;
    display: inline-block;
    float: none;
    line-height: 1;
  }
}

.cdmx-app {
  @include tx-bgc($animation-long);
  background-color: $theme-background-alt;

  .navbar {
    @include tx-bgc($animation-slower);
    background-color: $theme-foreground;
  }

  .card {
    @include tx-bgc($animation-slow);
    background-color: $theme-foreground;
    border: 0 none !important;

    &.border {
      box-shadow: 0 2px 4px 0 rgba(26, 26, 26, 0.1),
        0 0 0 1px rgba(26, 26, 26, 0.1);
    }

    .card-header {
      background: white;
    }

    .card-body {
      color: black;
    }

    .card-footer {
      background-color: white;
    }
  }

  .bg-cdmx-mid {
    background-color: #00655c !important;
    h2 {
      color: white !important;
    }
  }

  h1 {
    @include tx-c($animation-fast);
    color: $h1;

    span {
      @include tx-c($animation-mid);
      color: $h1-alt;
    }
  }

  h2 {
    @include tx-c($animation-fast);
    color: $h2;
  }

  h3 {
    @include tx-c($animation-fast);
    color: $h2 !important;
  }

  p {
    @include tx-c($animation-fast);
    color: $h2;

    &.list-p {
      font-size: 1rem !important;
    }
  }

  a {
    @include tx-c($animation-fast);
    color: $link-normal !important;

    &:hover {
      color: $link-hover !important;
      text-decoration: none !important;
    }

    &.nav-link {
      font-size: 15px;
      padding-top: 0.2rem;
      padding-bottom: 0.7rem;
      font-weight: lighter !important;
      color: white !important;
      text-decoration: none !important;
    }
  }

  .inner-header {
    @include tx-bgc($animation-slow);
    background-color: $cdmx-background;

    h1 {
      @include tx-c($animation-fast);
      color: $h1;
    }
  }

  .form-group {
    font-family: "Poppins", sans-serif;
  }

  .text-cdmx {
    @include tx-c($animation-fast);
    color: #00312d !important;
  }

  .btn-default {
    color: $h1;
  }

  .bg-cdmx {
    @include tx-bgc($animation-slow);
    background-color: $cdmx-background-light;
  }

  &.dark {
    @include tx-bgc($animation-long);
    background-color: $theme-background-dark;

    .navbar {
      @include tx-bgc($animation-slower);
      background-color: #262c45;
    }

    .ProcessSetup {
      @include tx-bgc($animation-slow);
      background-color: #1b1e2e;
    }

    .card {
      @include tx-bgc($animation-slow);
      background-color: #1b1e2e;

      .card-header {
        background: transparent;
      }

      .card-body {
        color: white;
      }
    }

    .bg-cdmx {
      @include tx-bgc($animation-mid);
      background-color: #21353b;
    }

    .form-check {
      @include tx-c($animation-fast);
      color: $h1-dark;
    }

    h1 {
      @include tx-c($animation-fast);
      color: $h1-dark;

      span {
        @include tx-c($animation-fast);
        color: $h1-alt-dark;
      }
    }

    h2,
    h3,
    h4 {
      @include tx-c($animation-fast);
      color: $h2-dark !important;
    }

    p {
      color: white;

      &.list-p {
        color: #f4f4f4 !important;
      }
    }

    a {
      color: #28a745 !important;

      &:hover {
        color: #007db3 !important;
      }
    }

    textarea {
      border: 1px solid #ececec !important;
    }

    ul {
      color: white;

      &.navbar-nav {

        h4 {
          font-size: 17px;
          color: white !important;
          font-weight: 600;
        }

        .nav-link {
          color: white !important;
          font-weight: 500;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .alert {
      &-success {
        background-color: #00bc8c !important;
        color: white;
        border: 0 none;
      }
      &-warning {
        background-color: #f39c12 !important;
        border: 0 none;
      }
      &-info {
        background-color: #3498db;
        border: 0 none;
      }
      &-danger {
        background-color: #e74c3c;
        border: 0 none;
      }
      &-primary {
        background-color: #375a7f;
        border: 0 none;
      }
    }

    .text-cdmx {
      color: #00312d !important;
    }

    .inner-header {
      background-color: #21353b !important;

      h1 {
        color: white !important;
      }
    }

    .btn-default {
      color: $h1-dark;
      background: #21263b;
    }

    .form-control {
      background-color: #21263b;
      border: 1px solid #262c45;
      color: white;
    }

    .control-label {
      color: white;
    }

    .modal-content {
      background-color: #1b1e2e;
    }
  }
}

.modal {
  font-family: "Poppins", sans-serif;
}

.modal-content {
  background-color: #fff;
}

.modal-header {
  border-bottom: 0 none;
}

@media (max-width: 540px) {
  .cdmx-app {
    h1 {
      font-size: 1.5rem !important;

      span {
        margin-bottom: 1rem !important;
      }
    }

    h3 {
      font-size: 1.1rem;
    }

    p {
      font-size: 1rem !important;
      line-height: 1.1;

      &.list-p {
        font-size: 0.65em;
        color: #666;
      }
    }

    .inner-header {
      h1 {
        font-size: 1.95rem !important;
      }
    }

    .navbar-brand {
      img {
        width: 150px;
      }
    }
  }
}

.btn-link,
a {
  text-decoration: underline;
  color: green;
}

.rounded-corners {
  border-radius: 1.3rem !important;
  -webkit-border-radius: 1.3rem !important;
  -moz-border-radius: 1.3rem !important;
  overflow: hidden;
}

.rounded-corners-mid {
  border-radius: 0.625rem !important;
  -webkit-border-radius: 0.625rem !important;
  -moz-border-radius: 0.625rem !important;
  overflow: hidden;
}

.count_number {
  width: 30px;
  height: 30px;
  background-color: #04bd6b;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn-custom {
  border: 1px solid gray;
  color: #545454;
  background-color: white;
  padding: 6px 14px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;

  opacity: 0;
}

.modal {
  font-family: "Poppins", sans-serif;
}

.modal-header {
  border-bottom: 0 none !important;
}

.footer {
  background: #00312d;

  h3,
  h4 {
    font-size: 1.2rem !important;
    color: white !important;
  }

  a {
    color: white !important;
  }
}

.dark {
  .footer {
    background-color: #262c45 !important;

    a {
      color: white !important;
    }
  }
}

.footer-top {
  background: #d8d8d8;
}

.footer-links {
  font-size: 13px;
  color: white;

  li {
    line-height: 17px;
  }
}

.footer-greca {
  background-image: url("/assets/images/greca_desk.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hr-footer {
  border-top: 1px solid #22534d;
  margin-bottom: 54px;
}

.axis_text {
  font-weight: bold;
}

.axis_text line {
  display: none;
}

.nota-list {
  padding-left: 0;
  font-size: 15px !important;

  li {
    margin-bottom: 12px;
    line-height: 24px;

    ol {
      padding: 0px;
    }
  }
}

.nav-sidebar {
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  width: 14%;
}

@media (max-width: 1024px) {
  .nav-sidebar {
    width: 21%;
  }
}

.bg {
  &-metro {
    background-color: #EC5D2A !important;
  }
  &-metrobus {
    background-color: #BD2C34 !important;
  }
  &-transito {
    background-color: #9137AF !important;
  }
  &-agregados {
    background-color: #51B240 !important;
  }
  &-autopistas {
    background-color: #376E88 !important;
  }
  &-compartido {
    background-color: #7E7E7E !important;
  }
}

.frmb li{
  background: transparent !important;
}

.frmb-control li {
  background: #1b1e2e;
}

.header-field {
  h1 {
    font-size: 21px !important;
  }
}

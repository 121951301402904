.new-media-btn {
    display:block; width:100%; height:100%; color:$brand-text; background-color:$white; text-align:center; border-radius:10px; padding:36px 0 15px; margin-bottom:20px; @include block-shadow();
    
    .fa{ font-size:48px; margin-bottom:5px;}
    p{ font-size:18px; font-weight:300}
    
    &:hover {
      background-color:$gray; color:$white;  text-decoration: none; cursor: pointer;
    }
}

a {
	&.badge {
		color: white;
	}
}
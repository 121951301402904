

@font-face {
  font-family: 'sidebar-icons';
  src:url('fonts/sidebar-icons.eot?1g29un');
  src:url('fonts/sidebar-icons.eot?#iefix1g29un') format('embedded-opentype'),
    url('fonts/sidebar-icons.woff?1g29un') format('woff'),
    url('fonts/sidebar-icons.ttf?1g29un') format('truetype'),
    url('fonts/sidebar-icons.svg?1g29un#sidebar-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'sidebar-icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-setup:before {
  content: "\e600";
}
.icon-module:before {
  content: "\e601";
}
.icon-desktop:before {
  content: "\e602";
}
.icon-content:before {
  content: "\e603";
}
.icon-nav:before {
  content: "\e604";
}
.icon-users:before {
  content: "\e605";
}
